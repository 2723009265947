import React from 'react';
import './ScrollTop.css';

import ScrollToTop from "react-scroll-to-top";
 
 
 
const ScrollTop = () => {  

  return (
    // ScrollToTop component for scrolling to the top 
    <ScrollToTop
      smooth 
      width='22'
      height='22'
      viewBox="0 0 320 512"
      svgPath="M318 177.5c3.8-8.8 2-19-4.6-26l-136-144C172.9 2.7 166.6 0 160 0s-12.9 2.7-17.4 7.5l-136 144c-6.6 7-8.4 17.2-4.6 26S14.4 192 24 192H96l0 288c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32l0-288h72c9.6 0 18.2-5.7 22-14.5z"
    />) 
    
}   

export default ScrollTop; 