const events = [ 
    {
        id: 1,
        team1: 'united falcon',
        team2: 'wolf club',
        date: 'feb 21, 2024',
        time: '7:00 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '2nd',
    },
    {
        id: 2,
        team1: 'united falcon',
        team2: 'tigers club',
        date: 'feb 23, 2024',
        time: '7:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '4th',
    },
    {
        id: 3,
        team1: 'united falcon',
        team2: 'eagle club',
        date: 'feb 28, 2024',
        time: '2:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '6th',
    },
    {
        id: 4,
        team1: 'united falcon',
        team2: 'lions club',
        date: 'mar 1, 2024',
        time: '7:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '8th',
    },
    {
        id: 5,
        team1: 'united falcon',
        team2: 'hawk club',
        date: 'mar 2, 2024',
        time: '2:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '10th',
    },
    {
        id: 6,
        team1: 'united falcon',
        team2: 'wolf club',
        date: 'mar 6, 2024',
        time: '7:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '12th',
    },
    {
        id: 7,
        team1: 'united falcon',
        team2: 'tigers club',
        date: 'mar 8, 2024',
        time: '2:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '14th',
    },
    {
        id: 8,
        team1: 'united falcon',
        team2: 'eagle club',
        date: 'mar 12, 2024',
        time: '7:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '16th',
    },
    {
        id: 9,
        team1: 'united falcon',
        team2: 'lions club',
        date: 'mar 14, 2024',
        time: '2:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '18th',
    },
    {
        id: 10,
        team1: 'united falcon',
        team2: 'hawk club',
        date: 'mar 15, 2024',
        time: '7:30 PM',
        venue: 'national stadium, karachi', 
        series: 'National Cup - Season 2',
        matchNo: '20th',
    },
];

export default events;