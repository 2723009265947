import { images } from '../constants';

const home = [
    {    
        id: 1,
        image: images.home1,
        title: 'United, we play, United, we win!',
        content: 'The Falcon is the popular and most powerful team in the Tournament. It is establish to provide an opportunity to improve cricketing skill of young talented cricketers.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Teamwork makes the dream work!',
        content: 'The Falcon is the popular and most powerful team in the Tournament. It is establish to provide an opportunity to improve cricketing skill of young talented cricketers.', 
    },
    { 
        id: 3,
        image: images.home3,
        title: 'Bringing out the best in each other!',
        content: 'The Falcon is the popular and most powerful team in the Tournament. It is establish to provide an opportunity to improve cricketing skill of young talented cricketers.',     
    },

];

export default home;