import Images from "./images";

const cricketTeams = [
  {
    id: 1,
    name: 'united falcon',
    logo: Images.unitedFalcon,
    players: [
      {
        id: 1,
        name: 'James Anderson',
        role: 'Right-Hand Batsman',
      },
      {
        id: 2,
        name: 'John Smith',
        role: 'Right-Hand Batsman',
      },
      {
        id: 3,
        name: 'Robert Johnson',
        role: 'Right-Hand Batsman',
      },
      {
        id: 4,
        name: 'Michael Davis',
        role: 'Right-Hand Batsman',
      },
      {
        id: 5,
        name: 'William Brown',
        role: 'Right-Hand Batsman',
      },
      {
        id: 6,
        name: 'David Martinez',
        role: 'Left-Hand batsman, Left-Arm leg spin bowler',
      },
      {
        id: 7,
        name: 'Joseph Taylor',
        role: 'Right-Hand Batsman, Left-Arm leg spin bowler',
      },
      {
        id: 8,
        name: 'Charles Wilson',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 9,
        name: 'Thomas Thompson',
        role: 'Right-Arm fast bowler',
      },
      {
        id: 10,
        name: 'Daniel Garcia',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 11,
        name: 'Matthew Davis',
        role: 'Right-Arm fast bowler',
      },
    ],
  },
  {
    id: 2,
    name: 'wolf club',
    logo: Images.wolfClub,
    players: [
      {
        id: 1,
        name: 'Andrew Hernandez',
        role: 'Right-Hand Batsman',
      },
      {
        id: 2,
        name: 'Richard Lewis',
        role: 'Right-Hand Batsman',
      },
      {
        id: 3,
        name: 'Joshua Walker',
        role: 'Right-Hand Batsman',
      },
      {
        id: 4,
        name: 'Christopher Allen',
        role: 'Right-Hand Batsman',
      },
      {
        id: 5,
        name: 'Jacob Young',
        role: 'Right-Hand Batsman',
      },
      {
        id: 6,
        name: 'Nicholas Wright',
        role: 'Left-Hand batsman, Left-Arm leg spin bowler',
      },
      {
        id: 7,
        name: 'Ethan Lopez',
        role: 'Right-Hand Batsman, Left-Arm leg spin bowler',
      },
      {
        id: 8,
        name: 'Anthony Lee',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 9,
        name: 'Ryan Hall',
        role: 'Right-Arm fast bowler',
      },
      {
        id: 10,
        name: 'Benjamin Clark',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 11,
        name: 'Samuel Lewis',
        role: 'Right-Arm fast bowler',
      },
    ],
  },
  {
    id: 3,
    name: 'tigers club',
    logo: Images.tigersClub,
    players: [
        {
        id: 1,
        name: 'Jonathan King',
        role: 'Right-Hand Batsman',
      },
      {
        id: 2,
        name: 'Tyler Hill',
        role: 'Right-Hand Batsman',
      },
      {
        id: 3,
        name: 'Alexander Green',
        role: 'Right-Hand Batsman',
      },
      {
        id: 4,
        name: 'Brandon Adams',
        role: 'Right-Hand Batsman',
      },
      {
        id: 5,
        name: 'Zachary Baker',
        role: 'Right-Hand Batsman',
      },
      {
        id: 6,
        name: 'Caleb Nelson',
        role: 'Left-Hand batsman, Left-Arm leg spin bowler',
      },
      {
        id: 7,
        name: 'Kevin Carter',
        role: 'Right-Hand Batsman, Left-Arm leg spin bowler',
      },
      {
        id: 8,
        name: 'Benjamin Collins',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 9,
        name: 'Justin Edwards',
        role: 'Right-Arm fast bowler',
      },
      {
        id: 10,
        name: 'Aaron Harris',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 11,
        name: 'Brian Turner',
        role: 'Right-Arm fast bowler',
      },
    ],
  },    
  {
    id: 4,
    name: 'eagle club',
    logo: Images.eagleClub,
    players: [
      {
        id: 1,
        name: 'Eric Rivera',
        role: 'Right-Hand Batsman',
      },
      {
        id: 2,
        name: 'Jason Phillips',
        role: 'Right-Hand Batsman',
      },
      {
        id: 3,
        name: 'Timothy Torres',
        role: 'Right-Hand Batsman',
      },
      {
        id: 4,
        name: 'Steven Mitchell',
        role: 'Right-Hand Batsman',
      },
      {
        id: 5,
        name: 'Stephen Roberts',
        role: 'Right-Hand Batsman',
      },
      {
        id: 6,
        name: 'Edward Rodriguez',
        role: 'Left-Hand batsman, Left-Arm leg spin bowler',
      },
      {
        id: 7,
        name: 'Frank Cook',
        role: 'Right-Hand Batsman, Left-Arm leg spin bowler',
      },
      {
        id: 8,
        name: 'Gary Cooper',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 9,
        name: 'William Simmons',
        role: 'Right-Arm fast bowler',
      },
      {
        id: 10,
        name: 'Jeffrey Reed',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 11,
        name: 'Scott Morgan',
        role: 'Right-Arm fast bowler',
      },
    ],
  },    
  {
    id: 5,
    name: 'lions club',
    logo: Images.lionsClub,
    players: [
      {
        id: 1,
        name: 'Kenneth Perry',
        role: 'Right-Hand Batsman',
      },
      {
        id: 2,
        name: 'Raymond Powell',
        role: 'Right-Hand Batsman',
      },
      {
        id: 3,
        name: 'Gregory Long',
        role: 'Right-Hand Batsman',
      },
      {
        id: 4,
        name: 'Joshua Foster',
        role: 'Right-Hand Batsman',
      },
      {
        id: 5,
        name: 'Jerry Torres',
        role: 'Right-Hand Batsman',
      },
      {
        id: 6,
        name: 'Dennis Butler',
        role: 'Left-Hand batsman, Left-Arm leg spin bowler',
      },
      {
        id: 7,
        name: 'Walter Murphy',
        role: 'Right-Hand Batsman, Left-Arm leg spin bowler',
      },
      {
        id: 8,
        name: 'Patrick Foster',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 9,
        name: 'Peter Rivera',
        role: 'Right-Arm fast bowler',
      },
      {
        id: 10,
        name: 'Harold Cox',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 11,
        name: 'Douglas Diaz',
        role: 'Right-Arm fast bowler',
      },
    ],
  },     
  { 
    id: 6,
    name: 'hawk club',
    logo: Images.hawkClub,
    players: [
      {
        id: 1,
        name: 'Henry Price',
        role: 'Right-Hand Batsman',
      },
      {
        id: 2,
        name: 'Carl Wood',
        role: 'Right-Hand Batsman',
      },
      {
        id: 3,
        name: 'Arthur Reed',
        role: 'Right-Hand Batsman',
      },
      {
        id: 4,
        name: 'Ryan Simmons',
        role: 'Right-Hand Batsman',
      },
      {
        id: 5,
        name: 'Lawrence Torres',
        role: 'Right-Hand Batsman',
      },
      {
        id: 6,
        name: 'Jesse Murphy',
        role: 'Left-Hand batsman, Left-Arm leg spin bowler',
      },
      {
        id: 7,
        name: 'Craig Butler',
        role: 'Right-Hand Batsman, Left-Arm leg spin bowler',
      },
      {
        id: 8,
        name: 'Alexander Watson',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 9,
        name: 'Russell Powell',
        role: 'Right-Arm fast bowler',
      },
      {
        id: 10,
        name: 'Louis Foster',
        role: 'Right-Arm medium fast bowler',
      },
      {
        id: 11,
        name: 'Johnny Price',
        role: 'Right-Arm fast bowler',
      },
    ],
  },
]

export default cricketTeams;