import { images } from ".";

const sponsors = [
    images.sponsor1,
    images.sponsor2,
    images.sponsor3,
    images.sponsor4,
    images.sponsor5, 
    images.sponsor6,
    images.sponsor7,
    images.sponsor8,
];

export default sponsors;