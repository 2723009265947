import { images } from '.';

const management = [ 
    {
        id: 1, 
        image: images.team1,
        name: 'Colin Cowdrey',
        title: 'Head Coach',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 2,
        image: images.team2,
        name: 'Barry Wood',
        title: 'Assistant Coach',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 3,
        image: images.team3,
        name: 'Peter Lever',
        title: 'Batting Coach',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 4,
        image: images.team4,
        name: 'Frank Hayes',
        title: 'Bowling Coach',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 5,
        image: images.team5,
        name: 'Robin Jackman',
        title: 'Fielding Coach',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 6,
        image: images.team6,
        name: 'David Steele',
        title: 'Team Manager',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 7,
        image: images.team7,
        name: 'Graham Stevenson',
        title: 'Team Analyst',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 
    {
        id: 8,
        image: images.team8,
        name: 'Richard Ellison',
        title: 'Media Manager',
        accounts: { 
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
    }, 

];

export default management;