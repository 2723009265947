import { images } from '.';

const players = [  
    {
        id: 1,
        image: images.player1,
        name: 'John Snow',
        title: 'Batter/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    }, 
    {
        id: 2,
        image: images.player2,
        name: 'Bob Woolmer',
        title: 'Batter',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    }, 
    {
        id: 3,
        image: images.player3,
        name: 'Mike Hendrick',
        title: 'Batter',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    }, 
    {
        id: 4,
        image: images.player4,
        name: 'Mike Smith',
        title: 'Batter',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    }, 
    {
        id: 5,
        image: images.player5,
        name: 'John Lever',
        title: 'Batter',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 6,
        image: images.player6,
        name: 'Peter Willey',
        title: 'All Rounder',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 7,
        image: images.player7,
        name: 'Clive Radley',
        title: 'All Rounder',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 8,
        image: images.player8,
        name: 'Graham Dilley',
        title: 'All Rounder',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 9,
        image: images.player9,
        name: 'Vic Marks',
        title: 'All Rounder',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 10,
        image: images.player10,
        name: 'Jack Richards',
        title: 'All Rounder',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 11,
        image: images.player11,
        name: 'Chris Smith',
        title: 'Bowler',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 12,
        image: images.player12,
        name: 'Richrd ellison',
        title: 'Bowler',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 13,
        image: images.player13,
        name: 'Tim Robinson',
        title: 'Bowler',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 14,
        image: images.player14,
        name: 'James Whitaker',
        title: 'Bowler',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 15,
        image: images.player15,
        name: 'Chris Lewis',
        title: 'Bowler',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 16,
        image: images.player16,
        name: 'Steve Watkin',
        title: 'Bowler',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Pakistani',
            DOB: '30 July, 1994 (Age 28)',
            height: '6ft (183)', 
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Batsman'
        },
        careerInfo: {
            clubDebut: '2015',
            curClub: 'United Falcon CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },

];

export default players;