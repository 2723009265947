import { images } from ".";

const testimonials = [ 
    {  
        id: 1,
        image: images.testimonial1, 
        name: 'Bruce Taylor', 
        title: 'Manager',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    }, 
    { 
        id: 2,
        image: images.testimonial2, 
        name: 'Liz Allan', 
        title: 'Founder',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
    { 
        id: 3,
        image: images.testimonial3, 
        name: 'John Parker', 
        title: 'Customer',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },    
    { 
        id: 4,
        image: images.testimonial4, 
        name: 'Ethna Rouse', 
        title: 'Customer',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
];

export default testimonials;